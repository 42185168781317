import React, { useEffect } from "react";

export const Subscribe = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.mediasolution.io/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on component unmount
    };
  }, []);

  return (
    <div style={{ marginTop: "200px" }}>
      <h1 style={{ textAlign: "center", fontSize: "25px" }}>RateMe Subscribe</h1>
      <iframe
        src="https://link.mediasolution.io/widget/form/1dwrUY1dRCwgLKIWo0QM"
        style={{ width: "100%", height: "402px", border: "none", borderRadius: "3px" }}
        id="inline-1dwrUY1dRCwgLKIWo0QM"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Newsletter Email List"
        data-layout-iframe-id="inline-1dwrUY1dRCwgLKIWo0QM"
        data-form-id="1dwrUY1dRCwgLKIWo0QM"
        title="Newsletter Email List"
      ></iframe>
    </div>
  );
};
