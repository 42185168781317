import React, { useEffect } from 'react';

export const SignUp = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://link.mediasolution.io/js/form_embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on component unmount
    };
  }, []);

  return (
    <div style={{ marginTop: "200px" }}>
      <h1 style={{ textAlign: "center" }}>Sign Up</h1>
      <iframe
        src="https://link.mediasolution.io/widget/form/p0Lal1S8uOerkM8G9LPC"
        style={{ width: "100%", height: "764px", border: "none", borderRadius: "3px" }}
        id="inline-p0Lal1S8uOerkM8G9LPC"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Sign Up Form"
        data-layout-iframe-id="inline-p0Lal1S8uOerkM8G9LPC"
        data-form-id="p0Lal1S8uOerkM8G9LPC"
        title="Sign Up Form"
      ></iframe>
    </div>
  );
};





// تصميم شاشة جديدة //

// import React, { useEffect } from "react";
// import {
//   Box,
//   Container,
//   Typography,
//   TextField,
//   Button,
//   FormControl,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Checkbox,
//   Grid,
//   InputAdornment,
// } from "@mui/material";
// import {
//   Person,
//   Email,
//   Phone,
//   Business,
//   CheckCircle,
// } from "@mui/icons-material";
// import { useTheme } from "@mui/material/styles";

// export const SignUp = () => {
//   const theme = useTheme();

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://link.mediasolution.io/js/form_embed.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script); 
//     };
//   }, []);

//   return (
//     <Box
//       sx={{
//         mt: 10,
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         px: { xs: 2, sm: 4 },
//         backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#000000", // Background color based on theme
//         py: 4,
//         minHeight: "100vh", // Ensure the container takes up the full viewport height
//       }}
//     >
//       <Typography
//         variant="h4"
//         component="h1"
//         sx={{
//           mb: 4,
//           textAlign: "center",
//           fontWeight: "bold",
//           color: theme.palette.text.primary,
//         }}
//       >
//         Sign Up
//       </Typography>
//       <Container
//         sx={{
//           width: "100%",
//           maxWidth: "md",
//           backgroundColor: theme.palette.background.paper,
//           padding: 4,
//           borderRadius: "8px",
//           boxShadow: 3,
//           border: `1px solid ${
//             theme.palette.mode === "light" ? "#000000" : "#ffffff"
//           }`, // Border color based on theme
//           display: "flex",
//           flexDirection: "column",
//           gap: 2,
//         }}
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="First Name"
//               variant="outlined"
//               fullWidth
//               required
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Person color="action" />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Last Name"
//               variant="outlined"
//               fullWidth
//               required
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Person color="action" />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="Email"
//               variant="outlined"
//               fullWidth
//               required
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Email color="action" />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="Phone"
//               variant="outlined"
//               fullWidth
//               required
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Phone color="action" />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               label="Company Name"
//               variant="outlined"
//               fullWidth
//               required
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Business color="action" />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography
//               variant="subtitle1"
//               sx={{
//                 mb: 1,
//                 color: theme.palette.text.primary,
//                 textAlign: "left",
//               }}
//             >
//               Company Size *
//             </Typography>
//             <FormControl component="fieldset" fullWidth>
//               <RadioGroup
//                 aria-labelledby="company-size-label"
//                 name="company-size"
//               >
//                 <FormControlLabel
//                   value="1-5"
//                   control={<Radio />}
//                   label="1 - 5 Employees"
//                 />
//                 <FormControlLabel
//                   value="6-15"
//                   control={<Radio />}
//                   label="6 - 15 Employees"
//                 />
//                 <FormControlLabel
//                   value="16-30"
//                   control={<Radio />}
//                   label="16 - 30 Employees"
//                 />
//                 <FormControlLabel
//                   value="31-60"
//                   control={<Radio />}
//                   label="31 - 60 Employees"
//                 />
//                 <FormControlLabel
//                   value="61-100"
//                   control={<Radio />}
//                   label="61 - 100 Employees"
//                 />
//                 <FormControlLabel
//                   value="100+"
//                   control={<Radio />}
//                   label="+ 100 Employees"
//                 />
//               </RadioGroup>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12}>
//             <FormControlLabel
//               control={<Checkbox />}
//               label="I agree to terms & conditions provided by the company. By providing my phone number, I agree to receive text messages from the business."
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button
//               color="primary"
//               variant="contained"
//               size="medium" 
//               sx={{
//                 bgcolor: (theme) =>
//                   theme.palette.mode === "light" ? "#7C26D6" : "#7C26D6",
//                 color: "white",
//                 "&:hover": {
//                   bgcolor: "#5b1f99", 
//                 },
//                 borderRadius: "8px", 
//                 px: 3, 
//                 py: 1.5,
//               }}
//               startIcon={<CheckCircle />} 
//             >
//               Sign Up
//             </Button>
//           </Grid>
//           <Grid item xs={12}>
//             <Box
//               sx={{
//                 mt: 2,
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: "100px",
//                 border: `1px solid ${
//                   theme.palette.mode === "light" ? "#000000" : "#ffffff"
//                 }`, // Border color based on theme
//                 borderRadius: "4px",
//                 backgroundColor:
//                   theme.palette.mode === "light" ? "#ffffff" : "#000000",
//               }}
//             >
//               {/* Placeholder for Captcha */}
//               <Typography variant="body2" color="text.secondary">
//                 Captcha Placeholder
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//   );
// };
