// import { createTheme } from '@mui/material/styles';

// export const lightTheme = createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#1976d2', 
//     },
//     secondary: {
//       main: '#dc004e',
//     },
//     background: {
//       default: '#fafafa',
//       paper: '#ffffff',
//     },
//     text: {
//       primary: '#000000',
//       secondary: '#555555',
//     },
//   },
//   typography: {
//     fontFamily: 'Roboto, Arial, sans-serif',
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: '8px',
//         },
//       },
//     },
//     MuiAppBar: {
//       styleOverrides: {
//         root: {
//           boxShadow: 'none', 
//         },
//       },
//     },
//   },
// });

// export const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#90caf9', 
//     },
//     secondary: {
//       main: '#f48fb1',
//     },
//     background: {
//       default: '#121212', 
//       paper: '#424242',
//     },
//     text: {
//       primary: '#ffffff', 
//       secondary: '#bbbbbb', 
//     },
//   },
//   typography: {
//     fontFamily: 'Roboto, Arial, sans-serif',
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: '8px', 
//         },
//       },
//     },
//     MuiAppBar: {
//       styleOverrides: {
//         root: {
//           boxShadow: 'none', 
//         },
//       },
//     },
//   },
// });


import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Inter", "sans-serif"',
  },
});

// تعريف الثيم الداكن
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#ffffff', 
      secondary: '#cccccc', 
    },

  },
  typography: {
    fontFamily: '"Inter", "sans-serif"', 
  },
});
