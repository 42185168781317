import React, { useEffect } from 'react';

export const ContactUs = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://link.mediasolution.io/js/form_embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on component unmount
    };
  }, []);

  return (
    <div style={{ marginTop: "200px" }}>
      <h1 style={{ textAlign: "center" }}>Contact Us</h1>
      <iframe
        src="https://link.mediasolution.io/widget/form/p0Lal1S8uOerkM8G9LPC"
        style={{ width: "100%", height: "764px", border: "none", borderRadius: "3px" }}
        id="inline-p0Lal1S8uOerkM8G9LPC"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Sign Up Form"
        data-layout-iframe-id="inline-p0Lal1S8uOerkM8G9LPC"
        data-form-id="p0Lal1S8uOerkM8G9LPC"
        title="Sign Up Form"
      ></iframe>
    </div>
  );
};
