// import * as React from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// export default function FAQ() {
//   const [expanded, setExpanded] = React.useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   return (
//     <Container
//       id="faq"
//       sx={{
//         pt: { xs: 4, sm: 12 },
//         pb: { xs: 8, sm: 16 },
//         position: 'relative',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         gap: { xs: 3, sm: 6 },
//       }}
//     >
//       <Typography
//         component="h2"
//         variant="h4"
//         color="text.primary"
//         sx={{
//           width: { sm: '100%', md: '60%' },
//           textAlign: { sm: 'left', md: 'center' },
//         }}
//       >
//         Why Us
//       </Typography>
//       <Box sx={{ width: '100%' }}>
//         <Accordion
//           expanded={expanded === 'panel1'}
//           onChange={handleChange('panel1')}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel1d-content"
//             id="panel1d-header"
//           >
//             <Typography component="h3" variant="subtitle2">
//               How do I contact customer support if I have a question or issue?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: '100%', md: '70%' } }}
//             >
//               You can reach our customer support team by emailing
//               <Link> support@email.com </Link>
//               or calling our toll-free number. We&apos;re here to assist you
//               promptly.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === 'panel2'}
//           onChange={handleChange('panel2')}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel2d-content"
//             id="panel2d-header"
//           >
//             <Typography component="h3" variant="subtitle2">
//               Can I return the product if it doesn&apos;t meet my expectations?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: '100%', md: '70%' } }}
//             >
//               Absolutely! We offer a hassle-free return policy. If you&apos;re not
//               completely satisfied, you can return the product within [number of
//               days] days for a full refund or exchange.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === 'panel3'}
//           onChange={handleChange('panel3')}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel3d-content"
//             id="panel3d-header"
//           >
//             <Typography component="h3" variant="subtitle2">
//               What makes your product stand out from others in the market?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: '100%', md: '70%' } }}
//             >
//               Our product distinguishes itself through its adaptability, durability,
//               and innovative features. We prioritize user satisfaction and
//               continually strive to exceed expectations in every aspect.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === 'panel4'}
//           onChange={handleChange('panel4')}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel4d-content"
//             id="panel4d-header"
//           >
//             <Typography component="h3" variant="subtitle2">
//               Is there a warranty on the product, and what does it cover?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: '100%', md: '70%' } }}
//             >
//               Yes, our product comes with a [length of warranty] warranty. It covers
//               defects in materials and workmanship. If you encounter any issues
//               covered by the warranty, please contact our customer support for
//               assistance.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//       </Box>
//     </Container>
//   );
// }
//  الكود القديم لل FAQ 


import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function WhyUs() {
  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        textAlign: 'center',
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{ marginBottom: 4 }}
      >
        Why Us
      </Typography>
      
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginBottom: 2 }}
      >
        Allowing the market to evaluate your employees is a highly valued metric.
      </Typography>

      <Box
        sx={{
          width: { xs: '100%', md: '75%' },
          textAlign: 'left',
        }}
      >
        <Typography variant="body1" paragraph>
          With over 1,000 data points per year, unlike any other, this daily operation
          “forced method” ensures precise accuracy and enables the company to optimize its
          workforce by addressing low performance, retaining high performers, and ultimately
          driving outstanding revenue growth with better cost savings.
        </Typography>
        <Divider sx={{ marginY: 4 }} />
        <Typography variant="h6" color="primary" sx={{ fontStyle: 'italic' }}>
          “RATEME is the Internal Solution for External Growth.”
        </Typography>
      </Box>
    </Container>
  );
}
