import * as React from "react";
import "../App.css";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import eee from "../images/Frame.svg";
export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage: theme.palette.mode === "light" ? "white" : "black",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
        marginTop:"20px"
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 10, sm: 14 },
          pb: { xs: 6, sm: 10 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "80%" } }}>
          <Typography
            component="h1"
            variant="h2"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: {
                xs: "2rem",
                sm: "2.2rem",
                md: "2.5rem",
                lg: "3rem",
              },
            }}
          >
            <span>Human Rating  &nbsp;</span>
            <Typography
              component="span"
              variant="h2"
              sx={{
                color: "#7C26D6",
                display: "inline",
              }}
            >
              Measurement
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="text.secondary"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            Our system delivers the most precise quantitative measurement, 
            <br />
            utilizing over 1,000 market survey results per person annually.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          ></Stack>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 6, sm: 8 },
            alignSelf: "center",
            height: { xs: 150, sm: 600 },
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        >
          <img
            src={eee}
            alt="a"
            style={{ width: "100%", height: "100%", borderRadius: "20px" }}
          />
        </Box>
      </Container>
    </Box>
  );
}
