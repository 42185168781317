import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import './styles.css';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Dashboard',
    description: 
      'Providing the most important metrics, categorized in a professional order accordingly. Entities Covered: Company name, associated brands, and affiliated firms. Detailed Insights: Structured and categorized details, including employee information: Employee Name, Rates, and Job Titles. Dynamic Filtering: Capability to sort data based on Rating (from highest to lowest) and Chronological Rating (from oldest to newest).',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Mobile Integration',
    description:
      'Access via Mobile Application to monitor all metric updates. An alternative provided for portable mobile users to monitor their staff, including day to day upgrades and notifications.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Analytics',
    description:
      'Day to day metrics readings, and end of month summarized analytics. Analytics including visualization of: Data representations, Table Charts, Pie Charts, Graphs to illustrate data. Additional Value of Comparison between (2 Employees) and (3 Employees) to: Evaluate and benchmark performance, Identifying the best / worst performers, Assess the trajectory.',
    imageLight: 'url("/static/images/templates/templates-images/analytics-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/analytics-dark.png")',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Additional Features',
    description:
      'Explore additional features and value points of the product to enhance user experience and operational efficiency.',
    imageLight: 'url("/static/images/templates/templates-images/features-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/features-dark.png")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Typography component="h2" variant="h4" color="text.primary" align="center" sx={{ mb: 4 }}>
        Product Features
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            spacing={2}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  border: '1px solid',
                  borderColor: selectedItemIndex === index ? 'primary.main' : 'grey.800',
                  borderRadius: '10px',
                  p: 3,
                  textAlign: 'left',
                  backgroundColor: 'background.paper', 
                  color: 'text.primary',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'background.paper', 
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      color: selectedItemIndex === index ? '#7C26D6' : 'inherit',
                    }}
                  >
                    {React.cloneElement(icon, {
                      sx: {
                        color: selectedItemIndex === index ? '#7C26D6' : 'inherit',
                      },
                    })}
                  </Box>
                  <div>
                    <Typography
                      fontWeight="medium"
                      sx={{ color: selectedItemIndex === index ? '#7C26D6' : 'text.primary', textTransform: 'capitalize' }}
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: selectedItemIndex === index ? 'text.primary' : 'text.secondary', textTransform: 'capitalize', fontSize: '0.75rem' }}>
                      {description}
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Box
        component={Card}
        variant="outlined"
        sx={{
          display: { xs: 'auto', sm: 'none' },
          mt: 4,
          borderRadius: '10px',
          borderColor: 'grey.800',
        }}
      >
        <Box
          sx={{
            backgroundImage: (theme) =>
              theme.palette.mode === 'light'
                ? items[selectedItemIndex].imageLight
                : items[selectedItemIndex].imageDark,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
          }}
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography color="text.primary" variant="body2" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
            {selectedFeature.title}
          </Typography>
          <Typography color="text.secondary" variant="body2" sx={{ my: 0.5, textTransform: 'capitalize', fontSize: '0.75rem' }}>
            {selectedFeature.description}
          </Typography>
          <Link
            color="#7C26D6" 
            variant="body2"
            fontWeight="bold"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              '& > svg': { transition: '0.2s' },
              '&:hover > svg': { transform: 'translateX(2px)' },
            }}
          >
            <span>Learn more</span>
            <ChevronRightRoundedIcon
              fontSize="small"
              sx={{ mt: '1px', ml: '2px', color: '#7C26D6' }}
            />
          </Link>
        </Box>
      </Box>
    </Container>
  );
}
