

import React, { useState } from 'react';
import './App.css';
import AppAppBar from './components/AppAppBar'; 
import Hero from './components/Hero'; 
import Testimonials from './components/Testimonials'; 
import Features from './components/Features'; 
import Highlights from './components/Highlights'; 
import FAQ from './components/FAQ'; 
import Footer from './components/Footer'; 
import LogoCollection from './components/LogoCollection'; 
import Checkout from './components/Checkout';
import Pricing from './components/Pricing'
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './components/theme'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Subscribe } from './components/Subscribe';
import { SignUp } from './components/SignUp';
import { ContactUs } from './components/ContactUs';

function App() {
  const [mode, setMode] = useState('dark');

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />
      <div className="App">
      <Router>
        <header>
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        </header>
        <main>
          
            <Routes>
              <Route path="/" element={
                <>
                  <Hero /> 
                  <Features /> 
                  <Testimonials /> 
                  <Highlights /> 
                  <Pricing />
                  <FAQ /> 
                  <LogoCollection /> 
                  <Footer /> 
                </>
              } />
              <Route path="/checkout" element={<Checkout />} />
              <Route path='/subscribe' element ={<Subscribe />} />
              <Route path='/signUp' element ={ <SignUp />} />
              <Route path='/ContactUs' element ={ <ContactUs />} />
            </Routes>
          
        </main>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
